import React, { Fragment, MouseEvent } from "react";

import AutocompleteConfig from "src/components/autocomplete/autocomplete_config";
import { BaseItem } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { ReactComponent as SearchIcon } from "images/icons/search-alternate.svg";
import {
  restaurantPath,
  searchRestaurantsPath,
  storeAttachmentImageryPath,
  storefrontsPastStoresPath
} from "src/routes";
import { LiteClient } from "algoliasearch/lite";
import { ReactComponent as ImageSquareFallback } from "images/image-square-fallback.svg";
import searchInsights from "search-insights";
import { AvailabilityLabels } from "src/components/autocomplete_renderer";

export interface StoreResult extends BaseItem {
  objectID: string | number;
  name: string;
  slug: string;
  __autocomplete_queryID?: string;
  image_thumbnail_id?: number;
}

const CLOSED_FOR_NOW = 'Closed for now';
const STORE_SEARCH_INPUT_ID = "search_bar";
const STORE_SEARCH_INPUT_ID_MOBILE = "search_bar_mobile";
const UNAVAILABLE_DUE_TO_ADDRESS = 'Unavailable';

export default class StoresAutocompleteConfig extends AutocompleteConfig<StoreResult> {
  storeIds: Array<number>;
  excludeAgeRestrictedStores: boolean;
  imageBaseUrl: string;
  availabilityLabels: AvailabilityLabels;
  storeSearchSuggestions: string[];

  constructor(indexName: string, imageBaseUrl: string, availabilityLabels: AvailabilityLabels, storeSearchSuggestions: string[], storeIds?: Array<number>, excludeAgeRestrictedStores?: boolean) {
    super(indexName);

    this.storeIds = storeIds || [];
    this.excludeAgeRestrictedStores = excludeAgeRestrictedStores || false;
    this.imageBaseUrl = imageBaseUrl;
    this.availabilityLabels = availabilityLabels;
    this.storeSearchSuggestions = storeSearchSuggestions;
  }

  emptyQueryCollectionId = "past_stores";
  emptyQueryCollectionTitle = "Order again";
  queryCollectionId = "stores"
  
  filterString(): string {
    const storesFilter = `(${this.storeIds.map((store_id) => `objectID:${store_id}`).join(" OR ")})`;
    const ageRestrictedFilter = this.excludeAgeRestrictedStores ? " AND requires_age_verification:false" : ""
    
    return storesFilter + ageRestrictedFilter;
  }

  emptyQueryResults() {
    return {
      sourceId: this.emptyQueryCollectionId,
      async getItems() {
        const resp = await fetch(storefrontsPastStoresPath(), { headers: { "Content-Type": "application/json" } });

        return (await resp.json() as { results: StoreResult[] } )["results"];
      },
      templates: {
        item() {
          return '<div></div>'
        }
      }
    }
  }

  queryResults(searchClient: LiteClient) {
    return {
      sourceId: this.queryCollectionId,
      getItems: ({ query }: { query: string }) => {
        if (query.length == 0) return [];
        return getAlgoliaResults<StoreResult>({
          searchClient,
          queries: [
            {
              indexName: this.indexName,
              params: {
                query,
                hitsPerPage: this.numResults,
                filters: this.filterString(),
              },
            },
          ],
        });
      },
      templates: {
        item() {
          return '<div></div>'
        }
      }
    };
  }

  searchResultsPath(query: string) {
    return searchRestaurantsPath({ "search_bar": query });
  }

  topSearchResult(): React.JSX.Element[] {
    return [];
  }

  topRecommendationResult(): React.JSX.Element[] {
    return [];
  }

  expandResults() {
    return undefined;
  }

  upperCaseStoreStyle(item: string) {
    return item.charAt(0).toUpperCase() + item.slice(1)
  }

  populateAutoCompleteWithStoreStyle(event: MouseEvent) {
    const newSearch = (event.currentTarget as HTMLAnchorElement).innerText

    const input = document.getElementById(STORE_SEARCH_INPUT_ID);
    const mobileInput = document.getElementById(STORE_SEARCH_INPUT_ID_MOBILE);
    ([mobileInput, input]).map(inputElement => ((inputElement as HTMLInputElement).value = newSearch));
    input?.dispatchEvent(new Event('input'));
  }

  storeStyleText() {
    return <>
      <p className="container label-text description">Need some inspiration?</p>
      <div className="container is-flexbox flex-wrap">
        {this.storeSearchSuggestions && this.storeSearchSuggestions.map((item) => {
          return <Fragment key={item}>
            <a onClick={(event) => {this.populateAutoCompleteWithStoreStyle(event)}} onMouseDown={event => event.preventDefault()} className="chip is-action space-mb-xs space-mr-xs"><SearchIcon className="icon streamline-icon" /><span>{this.upperCaseStoreStyle(item)}</span></a>
          </Fragment>
        })}
      </div>
    </>
  }
  
  itemComponent(item: StoreResult, index: number): React.JSX.Element {
    const imageUrl = item.image_thumbnail_id ? `${this.imageBaseUrl}/${storeAttachmentImageryPath(item.image_thumbnail_id)}` : undefined;
    
    const onClick = (_event: MouseEvent) => {
      if (item.__autocomplete_queryID) {
        document.cookie = `store_search_query_id=${item.__autocomplete_queryID};Max-Age=3600` // Algolia ignores events that are sent more than an hour after the query time
        document.cookie = `store_search_position=${index + 1};Max-Age=3600`
        
        searchInsights("clickedObjectIDsAfterSearch", {
          index: this.indexName,
          eventName: "Store clicked",
          queryID: item.__autocomplete_queryID,
          objectIDs: [item.objectID.toString()],
          positions: [index + 1], // Positions start from 1,
        })
      }
    }

    const fallbackImage = <ImageSquareFallback className="thumbnail-image is-circle icon streamline-icon is-medium is-grayscale" />

    const isAvailable = this.availabilityLabels[item.objectID] && this.availabilityLabels[item.objectID] !== CLOSED_FOR_NOW;

    const image = <>
      {imageUrl ? <div
        className={`thumbnail-image is-circle flex-shrink-0 ${isAvailable ? '' : 'is-grayscale' }`}
        data-lazy-images-target="image"
        data-lazy-images-imagery-path-value={imageUrl}
      /> : fallbackImage}
    </>

    const entryLabel = () => {
      if (this.availabilityLabels[item.objectID]) {
        return this.availabilityLabels[item.objectID]
      } else {
        return UNAVAILABLE_DUE_TO_ADDRESS;
      }
    }

    const entry = <>
      {image}
      <div className="is-flexbox justify-content-between align-items-center flex-grow-1 is-flex-gap-xs">
        <span className={`label-text is-tiny-text ${isAvailable ? '' : 'is-muted-text'}`}>{item.name}</span>
        <span className={`badge ${isAvailable ? 'is-solid' : 'is-subtle is-muted-text'} is-tiny-text description flex-shrink-0 align-self-start`}>
          {entryLabel()}
        </span>
      </div>
    </>
    
    return <a key={item.objectID} href={restaurantPath(item.slug)} onClick={onClick} className="list-item">{entry}</a>
  }
}
